<template>
    <KCourse loader-class="MBcont" course-id="3" title="Роспись и парик" :items="items">
        <div class="MBtextcont">
            <p>Все необходимые материалы можно <a target=”_blank” href="https://docs.google.com/document/d/1lacXMu9c6NPcr633P6bLJMQQFaJHXVncXYttQcvVyPg/edit?usp=sharing"> найти тут</a></p>
        </div>

        <VideoView video-id="d9cdb23f53264e349b4746fae85ce433"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Где искать образ?</b></p>
            <ul>
                <li>Нарисуйте</li>
                <li>Фильмы, сериалы, мультфильмы</li>
                <li>Книги, герои, сказки, легенды</li>
                <li>Природа, животные, насекомые</li>
                <li>Картины художников</li>
                <li>Старые фотографии</li>
                <li>Этника, история костюма</li>
                <li>Лучшие модные дома</li>
                <li><a target=”_blank” href="https://www.pinterest.ru/"></a> Pinterest.ru</li>
            </ul>

            <p class="nomargin"><b>Совмещайте несовместимое</b></p>
            <ul>
                <li>Альбинос и африканка</li>
                <li>Ребенок и викинг</li>
            </ul>
            <p class="nomargin"><b>Определите цветовую гамму</b></p>
            <p>Один главный + два, три дополнительных. <a target=”_blank” href="https://color.romanuke.com/"> В помощь сервис.</a></p>
            <p class="nomargin"><b>Итого, что у вас должно быть готово:</b></p>
            <ol>
                <li>Определитесь с образом и цветовой гаммой (соберите образ, используя картинки, ткани, волосы, кружева, бисер)</li>
                <li>Отшкурьте все детали до идеально гладкой поверхности (сначала используя грубую шкурку, потом финишную + влажный следок)</li>
                <li>Уберите всю карандашную разметку (с помощью шкурки или влажного следка)</li>
                <li>Проклейте шарнироприемники</li>
                <li>Сделайте 1-ую сборку</li>
            </ol>
        </div>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Образ", url:"/mycourses/draw/1"},
                { title: "Подготовка", url:"/mycourses/draw/2"},
                { title: "Грунтуем и красим", url:"/mycourses/draw/3"},
                { title: "Расписываем", url:"/mycourses/draw/4"},
                { title: "Делаем парик", url:"/mycourses/draw/5"},
            ],
        }
        }
    }
</script>